import React from 'react'
import LayoutWithHeaderAndFooter from '../components/layouts/LayoutWithHeaderAndFooter'
import { DisplayLarge } from '../components/typography'
import styled from '@emotion/styled'
import SEO from '../components/seo'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
import { Namespace } from '../constants/i18n'

const Seperator = styled.div`
  background-color: #54c4c9;
  width: 80%;
  height: 2px;
  margin: auto;
`

const About = (): JSX.Element => {
  const { t } = useTranslation(Namespace.About)

  return (
    <LayoutWithHeaderAndFooter heading={t('title')}>
      <SEO title="About" />
      <div className="flex flex-col mx-auto max-w-screen-md">
        <div>
          <DisplayLarge as="h2" className="mb-12">
            {t('aboutProject.heading')}
          </DisplayLarge>
          <p className="mb-8">
            <Trans
              t={t}
              i18nKey="aboutProject.paragraph.0"
              components={{
                a: (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a
                    className="underline text-blue-600"
                    href="https://www.tropmedres.ac/"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="MORU Tropical Health Network"
                  />
                ),
              }}
            />
          </p>
          <p className="mb-8">{t('aboutProject.paragraph.1')}</p>
          <p>{t('aboutProject.paragraph.2')}</p>
        </div>
        <div className="py-12">
          <Seperator />
        </div>
        <div>
          <DisplayLarge as="h2" className="mb-12">
            {t('mission.heading')}
          </DisplayLarge>
          <p>{t('mission.paragraph')}</p>
        </div>
      </div>
    </LayoutWithHeaderAndFooter>
  )
}

export default About
